import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import EventCard from "../components/EventCard";
import "../styles/homepage.scss";
import Logo from "../assets/topseat-logo.png";
import SearchButton from "../assets/search-button.png";
import LoaderSVG from "../assets/loader.svg";

// const API_VIVID_GET_URL = 'http://localhost:8080/vivid';
const API_VIVID_GET_URL = "https://lobster-app-xwd7l.ondigitalocean.app/vivid";

const HomePage: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [noData, setNoData] = useState<boolean>(false);

  const navigate = useNavigate();

  const getVividEvents = async (
    page = 0,
    limit = 10,
    classification: string | undefined,
    search = "",
    date = ""
  ) => {
    try {
      const params = {
        page,
        limit,
        classification: classification || undefined,
        search: search || undefined,
        date: date || undefined,
      };
      const response = await axios.get(API_VIVID_GET_URL, { params });

      const vividEvents = response.data.map((event: any) => ({
        _id: event.eventId,
        imageUrl: event?.imageUrl,
        min_price: event.minPrice,
        max_price: event.maxPrice,
        title: event.eventName,
        venueName: event.venueName,
        location: `${event.city} | ${event.state}`,
        date: event.localDate.split("T")[0],
        staticMapUrl: event.staticMapUrl,
        source: event.source,
        ticketUrl: event.ticketUrl,
      }));

      return vividEvents;
    } catch (error) {
      console.error("Error fetching Vivid events:", error);
      throw error;
    }
  };

  const fetchVividEvents = async (
    searchValue: string,
    page: number,
    classification: string | undefined,
    date: string
  ) => {
    setLoading(true);
    setNoData(false);
    try {
      const vividEvents = await getVividEvents(
        page,
        10,
        classification,
        searchValue,
        date
      );
      if (page === 0) {
        setEvents(vividEvents);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...vividEvents]);
      }
      setHasMore(vividEvents.length === 10);
      if (vividEvents.length === 0 && events.length === 0 && page === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("Error fetching events");
    } finally {
      setLoading(false);
    }
  };

  const handleSeeTicketsClick = (event: any) => {
    navigate(`/tickets/${event._id}`, {
      state: {
        event,
        ticketUrl: event.ticketUrl,
        staticMapUrl: event.staticMapUrl,
        name: event.name,
        date: event.date,
        location: event.location,
      },
    });
  };

  const handleCategoryClick = (category: string) => {
    if (category === selectedCategory) return;

    setSelectedCategory(category);
    setEvents([]);
    setPage(0);
    setNoData(false);
    setHasMore(true);
  };

  useEffect(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const isoString: string = tomorrow.toISOString();
    const classification =
      selectedCategory === "All" ? undefined : selectedCategory;
    fetchVividEvents(searchValue, page, classification, isoString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, selectedCategory]);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || !hasMore) return;
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const threshold = document.documentElement.offsetHeight - 200;
      if (scrollPosition >= threshold) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  // Debounce for search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchValue(searchInput);
      setPage(0);
      setHasMore(true);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  return (
    <div className="homepage">
      <header className="header">
        <img src={Logo} alt="TopSeat" className="logo" />
        <nav className="navbar-links">
          <a href="mailto:info@topseat.co">Contact us</a>
          <Link to="/faq">FAQ</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">ToS</Link>
        </nav>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search events"
            className="search-bar"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
          />
          <button className="search-button">
            <img src={SearchButton} alt="Search" />
          </button>
        </div>
      </header>

      <section className="all-events">
        <nav className="event-categories">
          <button
            className={selectedCategory === "All" ? "active" : ""}
            onClick={() => handleCategoryClick("All")}
          >
            All Events
          </button>
          <button
            className={selectedCategory === "Sports" ? "active" : ""}
            onClick={() => handleCategoryClick("Sports")}
          >
            Sports
          </button>
          <button
            className={selectedCategory === "Concert" ? "active" : ""}
            onClick={() => handleCategoryClick("Concert")}
          >
            Concert
          </button>
          <button
            className={selectedCategory === "Theater" ? "active" : ""}
            onClick={() => handleCategoryClick("Theater")}
          >
            Theater
          </button>
        </nav>

        <div className="event-grid">
          {loading && page === 0 ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <img src={LoaderSVG} height={50} width={50} alt="Loading..." />
            </div>
          ) : noData ? (
            <div>No data found for this category.</div>
          ) : (
            events.map((event, index) => (
              <EventCard
                key={`${event.source}-${event._id}-${index}`}
                image={event.imageUrl || Logo}
                title={event.title || event.name}
                location={`${event.venueName} | ${event.location} `}
                date={event.date || event.startDate}
                source={event.source}
                onSeeTicketsClick={() => handleSeeTicketsClick(event)}
              />
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
