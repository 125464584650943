import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SeeTickets from "../pages/SeeTickets";
import SeeVividTickets from "../pages/tickets";
import FAQ from "../pages/Faq";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/seetickets/:eventId" element={<SeeTickets />} />
      <Route path="/tickets/:eventId" element={<SeeVividTickets />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
    </Routes>
  );
};

export default AppRoutes;
