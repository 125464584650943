// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
}

p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

li {
  margin-left: 30px;
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0070f3;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #005bb5;
}`, "",{"version":3,"sources":["webpack://./src/styles/navbarlinks.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iCAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".page-container {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n  font-family: Arial, sans-serif;\n  line-height: 1.6;\n}\n\nh1 {\n  font-size: 2rem;\n  margin-bottom: 20px;\n}\n\nh3 {\n  font-size: 1.2rem;\n  margin-top: 20px;\n  margin-bottom: 10px;\n  color: #333;\n}\n\np {\n  margin-bottom: 15px;\n  font-size: 1rem;\n  color: #555;\n}\n\nli{\n  margin-left: 30px;\n}\n\n.back-button {\n  display: inline-block;\n  margin-top: 20px;\n  padding: 10px 20px;\n  background-color: #0070f3;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n  border-radius: 4px;\n  transition: background-color 0.3s;\n}\n\n.back-button:hover {\n  background-color: #005bb5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
