// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "League Spartan", sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

p, button, input, a {
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AACF;;AAEA;EACE,yCAAA;EACA,yBAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: 'League Spartan', sans-serif;  // Set League Spartan as default font\n  background-color: #f4f4f4;\n  color: #333;  // Default text color\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-weight: 600;  // Set bold weight for all headings\n}\n\np, button, input, a {\n  font-weight: 400;  // Normal weight for paragraphs, buttons, etc.\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
