import React, { useState, Dispatch, SetStateAction } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../styles/navbar.scss'
import Logo from '../assets/topseat-logo.png'
import SearchButton from '../assets/search-button.png'
import locationButton from '../assets/location.png'

interface NavbarProps {
  setEvents?: Dispatch<SetStateAction<any[]>>
  setPage?: Dispatch<SetStateAction<number>>
  setHasMore?: Dispatch<SetStateAction<boolean>>
  setNoData?: Dispatch<SetStateAction<boolean>>
  fetchEvents?: (value: string, page: number, category: string) => void
  typingTimeout?: NodeJS.Timeout | null
  DEBOUNCE_DELAY?: number
  selectedCategory?: string
  setTypingTimeout?: Dispatch<SetStateAction<NodeJS.Timeout | null>>
  showBackButton?: boolean
}

const Navbar: React.FC<NavbarProps> = ({
  setEvents,
  setPage,
  setHasMore,
  setNoData,
  fetchEvents,
  typingTimeout,
  DEBOUNCE_DELAY,
  selectedCategory,
  setTypingTimeout,
  showBackButton = false
}) => {
  const [locationValue, setLocationValue] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')

  const navigate = useNavigate()

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLocationValue(value)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchValue(value)

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    const timeout = setTimeout(() => {
      setEvents?.([])
      setPage?.(0)
      setHasMore?.(true)
      setNoData?.(false)
      fetchEvents?.(value, 0, selectedCategory || 'All')
    }, DEBOUNCE_DELAY || 300)

    setTypingTimeout?.(timeout)
  }

  return (
    <header className='header'>
      <img src={Logo} alt='TopSeat' className='logo' />
      <nav className='navbar-links'>
        <a href='mailto:info@topseat.co'>Contact us</a>
        <Link to='/faq'>FAQ</Link>
        <Link to='/privacy-policy'>Privacy Policy</Link>
        <Link to='/terms-of-service'>ToS</Link>
      </nav>
      {showBackButton ? (
        <button className='back-button' onClick={() => navigate(-1)}>
          Back
        </button>
      ) : (
        <>
          <div className='search-container'>
            <input
              type='text'
              placeholder='Location: New York'
              className='search-bar'
              onChange={handleLocationChange}
              value={locationValue}
            />
            <button className='location-button'>
              <img src={locationButton} alt='Search' />
            </button>
          </div>
          <div className='search-container'>
            <input
              type='text'
              placeholder='Search events'
              className='search-bar'
              onChange={handleSearchChange}
              value={searchValue}
            />
            <button className='search-button'>
              <img src={SearchButton} alt='Search' />
            </button>
          </div>
        </>
      )}
    </header>
  )
}

export default Navbar
