import React from 'react';
import '../styles/eventCard.scss';
import locationIcon from '../assets/location.png';
import calendarIcon from '../assets/calendar.png';
import arrowIcon from '../assets/arrow-right.png';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

interface EventCardProps {
  image: string;
  title: string;
  location: string;
  date: string;
  source: string;
  onSeeTicketsClick: () => void;
}

const EventCard: React.FC<EventCardProps> = ({
  image,
  title,
  location,
  date,
  source,
  onSeeTicketsClick
}) => {
  return (
    <div className='event-card'>
      <img src={image} alt={title} className='event-card-image' />
      <div className='event-card-content'>
        <h3>{title}</h3>
        <p>
          <img src={locationIcon} alt='Location Icon' className='icon' />
          {location}
        </p>
        <p>
          <img src={calendarIcon} alt='Calendar Icon' className='icon' />
          {formatDate(date)}
        </p>
        <button className='event-card-button' onClick={onSeeTicketsClick}>
          <span>See Tickets</span>
          <img src={arrowIcon} alt='Arrow Icon' className='arrow-icon' />
        </button>
      </div>
    </div>
  );
};

export default EventCard;
