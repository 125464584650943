import "../styles/navbarlinks.scss";
import "../styles/homepage.scss";
import Navbar from '../components/Navbar';

const Faq: React.FC = () => {

  return (
    <div className='homepage'>
      <Navbar showBackButton={true} />
      <div className='page-container'>
        <h1>FAQ</h1>
        <h3>1. What is TopSeat?</h3>
        <p>
          TopSeat is a platform that allows users to browse and search for tickets
          to live events (sports, concerts, theater) from a variety of reputable
          ticket sellers. We help you find the best seat and price for your event,
          but we don’t sell tickets directly.
        </p>

        <h3>2. How does TopSeat work?</h3>
        <p>
          You can search for an event, view ticket options from different sellers,
          and filter by price, seating area, or other preferences. Once you select
          a ticket, you’ll be redirected to the ticket seller’s site to complete
          your purchase.
        </p>

        <h3>3. Do I buy tickets from TopSeat?</h3>
        <p>
          No, TopSeat does not sell tickets directly. We show available tickets
          from other sellers. Once you choose a ticket, you’ll be redirected to
          the vendor’s site to complete the transaction.
        </p>

        <h3>4. Does TopSeat charge any fees?</h3>
        <p>
          No, TopSeat does not charge users for using the platform. Any fees
          related to the purchase of tickets will be displayed by the vendor
          during checkout.
        </p>

        <h3>5. Are the ticket sellers on TopSeat trustworthy?</h3>
        <p>
          We strive to feature tickets from reputable sellers. However, each
          vendor has its own policies, and TopSeat is not responsible for
          transactions. Please review the vendor’s terms and conditions before
          making a purchase.
        </p>

        <h3>6. Can I get a refund if my event is canceled?</h3>
        <p>
          Refunds and cancellations are handled by the ticket seller you purchase
          from. You should contact the seller directly regarding their refund
          policy.
        </p>

        <h3>7. How do I search for tickets?</h3>
        <p>
          Use the search bar on our homepage to enter the event, artist, team, or
          venue you're interested in. You can also browse events by category or
          date.
        </p>

        <h3>8. How do I contact TopSeat for support?</h3>
        <p>
          You can reach us via email at{' '}
          <a href='mailto:info@topseat.co'>info@topseat.co</a> for any
          support-related queries or feedback.
        </p>

        <h3>9. Does TopSeat have an app?</h3>
        <p>
          Currently, TopSeat is only available through our website. We hope to
          expand to a mobile app in the future.
        </p>

        <h3>10. In which cities is TopSeat available?</h3>
        <p>
          TopSeat is currently available only in the New York Metro area. We are
          actively working to expand our services to additional cities in the
          future, so please check back for updates!
        </p>
      </div>
    </div>
  )
}

export default Faq
