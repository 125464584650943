import '../styles/navbarlinks.scss'
import Navbar from '../components/Navbar'

const TermsOfService: React.FC = () => {
  return (
    <div className='homepage'>
      <Navbar showBackButton={true} />
      <div className='page-container'>
        <h1>Terms of Use</h1>

        <p>
          Welcome to TopSeat. By accessing or using our website (the “Site”) and
          services (collectively, the “Services”), you agree to comply with and
          be bound by these Terms of Use. Please read these terms carefully.
        </p>

        <h3>1. Acceptance of Terms</h3>
        <p>
          By using the Services, you agree to these Terms of Use, as well as any
          additional terms and conditions provided within specific sections of
          the Site. If you do not agree, please do not use our Services.
        </p>

        <h3>2. Services Description</h3>
        <p>
          TopSeat provides a platform for users to browse and search for live
          event tickets (sports, concerts, theater) available from various
          ticket vendors. TopSeat does not sell tickets directly but redirects
          users to third-party ticket sellers.
        </p>

        <h3>3. User Responsibilities</h3>
        <p>
          You agree to use the Services for lawful purposes only. You must not:
        </p>
        <ul>
          <li>
            Post, transmit, or share content that is fraudulent, misleading,
            harmful, or illegal.
          </li>
          <li>Interfere with the Site’s operation or other users’ access.</li>
        </ul>

        <h3>4. Ticket Transactions</h3>
        <p>
          TopSeat is not responsible for ticket sales or transactions that occur
          through third-party ticket sellers. All transactions are subject to
          the terms and policies of the vendor you purchase from. You agree that
          TopSeat is not liable for any disputes that arise with ticket vendors.
        </p>

        <h3>5. Intellectual Property</h3>
        <p>
          All content, trademarks, logos, and other materials provided by
          TopSeat are the intellectual property of TopSeat or our licensors. You
          agree not to reproduce, distribute, or create derivative works based
          on our content without our written permission.
        </p>

        <h3>6. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, TopSeat is not liable for any
          indirect, incidental, or consequential damages arising out of your use
          of our Services. Our total liability is limited to the amount you
          paid, if any, to use the Services.
        </p>

        <h3>7. Changes to Terms</h3>
        <p>
          TopSeat may modify these Terms of Use from time to time. We will
          notify you of any changes by updating the date at the top of this
          page. Continued use of the Services constitutes your acceptance of any
          revised terms.
        </p>

        <h3>8. Governing Law</h3>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of [Insert Jurisdiction]. Any legal actions must be brought within
          this jurisdiction.
        </p>

        <h3>9. Contact Information</h3>
        <p>
          For questions or concerns regarding these Terms of Use, please contact
          us at&nbsp;
          <a href='mailto:info@topseat.co'>info@topseat.co</a>.
        </p>
      </div>
    </div>
  )
}

export default TermsOfService
