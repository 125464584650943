import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../assets/topseat-logo.png";
import SearchButton from "../assets/search-button.png";
import "../styles/seetickets.scss";

const SeeTickets: React.FC = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const API_URI = "http://localhost:8080/events/one/";

  const getUniqueRandomNumbers = (count: number) => {
    const numbers = Array.from({ length: 7 }, (_, i) => i + 1);
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }
    return numbers.slice(0, count);
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const one_id = { id: eventId };
        const response = await axios.post(API_URI, one_id);
        const eventData = response.data;

        const randomAdditions = getUniqueRandomNumbers(4);
        const sortedTickets = randomAdditions
          .map((rand, index) => ({
            ...eventData,
            max_price: eventData.max_price + rand,
            id: index,
          }))
          .sort((a, b) => a.max_price - b.max_price);

        setEventDetails(sortedTickets);
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventDetails();
    }
  }, [eventId]);

  const goBack = () => navigate(-1);

  if (loading) return <div>Loading event details...</div>;

  if (!eventDetails) return <div>No event details found</div>;

  return (
    <div className="seeticketspage">
      <header className="header">
        <div className="logo-container">
          <button onClick={goBack} className="back-button">
            Back
          </button>
          <img src={Logo} alt="TopSeat" className="logo" />
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search events"
            className="search-bar"
          />
          <button className="search-button">
            <img src={SearchButton} alt="Search" />
          </button>
        </div>
      </header>

      <div className="listings-header">
        <h2>4 Listings</h2>
      </div>

      {eventDetails?.map((ticket: any) => (
        <div className="listings-grid" key={ticket.id}>
          <div className="ticket-card">
            <img src={ticket.imageUrl} alt="Event" className="ticket-image" />
            <div className="ticket-details">
              <div className="ticket-header">
                <h3 className="deal">Great Deal</h3>
                <a
                  href={ticket.ticketUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy Tickets
                </a>
              </div>
              <p className="price">${ticket.max_price}</p>
              <p className="section">Section 1, Row 1 | 1-4 Tickets</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SeeTickets;
