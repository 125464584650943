import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/topseat-logo.png";
import filter from "../assets/filters_icon.svg";
import SearchButton from "../assets/search-button.png";
import "../styles/tickets.scss";
import axios from "axios";

interface Ticket {
  seat: string;
  row: string;
  price: string;
  source: string;
  ticketUrl: string;
  staticMapUrl: string;
}

const SeeVividTickets: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [event, setEvent] = useState(location.state?.event || null);
  const [staticMapUrl, setStaticMapUrl] = useState(
    location.state?.staticMapUrl || ""
  );
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>("");
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { eventId } = useParams();
  const goBack = () => navigate(-1);
  const date = "";
  const formatDate = (dateString: string) => {
    console.log("dateString", dateString);
    const cleanDateString = dateString.split("[")[0];
    console.log(cleanDateString);
    const date = new Date(cleanDateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format:", dateString);
      return "Invalid date";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  useEffect(() => {
    if (!eventId) return;

    const fetchEventAndTickets = async () => {
      try {
        const vividResponse = await axios.get(
          `https://lobster-app-xwd7l.ondigitalocean.app/vivid/${eventId}`
        );
        const vividEvent = vividResponse.data;
        setEvent(vividEvent);
        if (vividEvent.staticMapUrl) {
          setStaticMapUrl(vividEvent.staticMapUrl);
        }

        const ticketsResponse = await axios.get(
          `https://lobster-app-xwd7l.ondigitalocean.app/vivid-tickets/${eventId}`
        );
        setTickets(ticketsResponse.data);
        setFilteredTickets(ticketsResponse.data);
      } catch (error) {
        console.error("Error fetching event or tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventAndTickets();
  }, [eventId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    const filtered = tickets.filter(
      (ticket) =>
        ticket.seat.toLowerCase().includes(e.target.value.toLowerCase()) ||
        ticket.row.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredTickets(filtered);
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleSort = (sortBy: "price" | "seat") => {
    let sortedTickets = [...filteredTickets];

    if (sortBy === "price") {
      sortedTickets.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (sortBy === "seat") {
      sortedTickets.sort((a, b) => a.seat.localeCompare(b.seat));
    }

    setFilteredTickets(sortedTickets);
    setIsFilterOpen(false); // Close the dropdown after sorting
  };

  if (!event) return <div>No event details found</div>;
  if (loading) return <div>Loading tickets...</div>;

  return (
    <div className="seeticketspage">
      <header className="header">
        <div className="logo-container">
          <img src={Logo} alt="TopSeat" className="logo" onClick={goBack} />
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search Tickets"
            className="search-bar"
            value={searchInput}
            onChange={handleSearch}
          />
          <button className="search-button">
            <img src={SearchButton} alt="Search" />
          </button>
        </div>
      </header>
      <div>
        <h1 className="eventTitle">{event.eventName}</h1>
        <span className="location">
          {event.venueName} | {event.city} | {event.state}
        </span>
        <br />
        <span className="date">{formatDate(event.localDate)}</span>
      </div>
      <div className="container">
        <div className="listings-header">
          <p className="ticket-listings-title">{tickets.length} Listings</p>
          <div className="sort-button">
            <img
              src={filter}
              alt="Filter"
              className="filter"
              onClick={toggleFilterDropdown}
            />
          </div>
          {isFilterOpen && (
            <div className="filter-dropdown">
              <button onClick={() => handleSort("price")}>Sort by Price</button>
              <button onClick={() => handleSort("seat")}>Sort by Seat</button>
            </div>
          )}
        </div>

        <div className="content">
          <div className="tickets-section">
            {filteredTickets.map((ticket, index) => (
              <div className="listings-grid" key={index}>
                <div className="ticket-card">
                  <img
                    src={event.imageUrl || Logo}
                    alt="Event"
                    className="ticket-image"

                    style={{
                      objectFit:
                        "cover"
                    }}
                  />
                  <div className="ticket-details">
                    <div className="ticket-header">
                      <a
                        href={ticket.ticketUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {new URL(ticket.ticketUrl).hostname}
                      </a>
                    </div>
                    <p className="price">
                      {ticket.source === "stubhub"
                        ? ticket.price
                        : `$${ticket.price}`}
                    </p>
                    <p className="section">
                      Seat {ticket.seat} | Row {ticket.row}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="map-section">
            <img
              src={staticMapUrl}
              alt="Event Location Map"
              className="event-map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeVividTickets;
