import '../styles/navbarlinks.scss'
import Navbar from '../components/Navbar'

const PrivacyPolicy: React.FC = () => {
  return (
    <div className='homepage'>
      <Navbar showBackButton={true} />
      <div className='page-container'>
        <h1>Privacy Policy</h1>

        <p>
          At TopSeat, we are committed to protecting your privacy. This Privacy
          Policy explains how we collect, use, and protect your information.
        </p>

        <h3>1. Information We Collect</h3>
        <ul>
          <li>
            <strong>Personal Information:</strong> When you sign up for an
            account, subscribe to updates, or use our Services, we may collect
            personal information such as your name, email address, and
            preferences.
          </li>
          <li>
            <strong>Usage Data:</strong> We may collect information about how
            you interact with the Site, including your IP address, browser type,
            and pages visited.
          </li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>We use your information to:</p>
        <ul>
          <li>Provide and improve our Services.</li>
          <li>Send you updates or alerts you’ve subscribed to.</li>
          <li>Analyze usage patterns to enhance user experience.</li>
        </ul>

        <h3>3. Cookies</h3>
        <p>
          We use cookies to personalize your experience and gather analytics
          about website performance. You can disable cookies in your browser,
          but some features of the Site may not work as intended.
        </p>

        <h3>4. Third-Party Ticket Sellers</h3>
        <p>
          We may share your information with third-party service providers who
          assist us in operating the Site and delivering Services. These
          providers are required to protect your information and use it only for
          the purposes we specify.
        </p>

        <h3>5. Data Security</h3>
        <p>
          We take the security of your personal information seriously and
          implement reasonable measures to protect it. However, no method of
          transmission over the internet is completely secure, and we cannot
          guarantee absolute security.
        </p>

        <h3>6. Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request corrections to inaccurate information.</li>
          <li>
            Request the deletion of your personal data, subject to certain legal
            obligations.
          </li>
        </ul>

        <h3>7. Changes to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of changes by updating the effective date at the top of this page.
        </p>

        <h3>8. Contact Information</h3>
        <p>
          For questions or concerns regarding this Privacy Policy, please
          contact us at&nbsp;
          <a href='mailto:info@topseat.co'>info@topseat.co</a>.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
